/* Color System */
:root {
  --primary: #294897;
  --primary-hover: #1e3670;
  --secondary: #5E3AEE;
  --secondary-hover: #4E2ADE;
  --gray-100: #f3f4f6;
  --gray-200: #e5e7eb;
  --gray-500: #6b7280;
  --gray-700: #374151;
  --gray-900: #111827;
  --white: #ffffff;
}

/* Common button styles */
.btn-primary {
  @apply px-4 py-2 bg-[#294897] text-white rounded-[6.4px] hover:bg-[#1e3670] transition-colors font-body;
}

.btn-secondary {
  @apply px-4 py-2 bg-[#5E3AEE] text-white rounded-[6.4px] hover:bg-[#4E2ADE] transition-colors font-body;
}

.btn-outline {
  @apply px-4 py-2 bg-white text-[#294897] border border-[#294897] rounded-[6.4px] hover:bg-gray-50 transition-colors font-body;
}

/* Common input styles */
.input-primary {
  @apply w-full px-4 py-2 border border-gray-300 rounded-full focus:outline-none focus:ring-2 focus:ring-[#294897] font-body;
}

/* Common card styles */
.card-base {
  @apply rounded-lg border bg-white text-gray-900 shadow-sm;
}

.card-header {
  @apply flex flex-col space-y-1.5 p-6;
}

.card-title {
  @apply text-2xl font-semibold leading-none tracking-tight font-heading text-gray-900;
}

.card-description {
  @apply text-sm text-gray-500 font-body;
}

/* Common typography styles */
.heading-primary {
  @apply text-2xl sm:text-3xl font-bold mb-4 font-heading text-gray-900;
}

.text-body {
  @apply text-base sm:text-lg mb-6 font-body text-gray-700;
}

/* Common layout styles */
.page-container {
  @apply flex flex-col min-h-screen full-viewport-height bg-white;
}

.main-content {
  @apply flex-grow flex justify-center items-center bg-white;
}

/* Chat bubble styles */
.chat-bubble {
  @apply mb-4 flex;
}

.chat-bubble-user {
  @apply justify-end;
}

.chat-bubble-ai {
  @apply justify-start;
}

.chat-bubble-content {
  @apply rounded-xl px-3 py-[18px];
}

.chat-bubble-content-user {
  @apply bg-[#5E3AEE] text-white max-w-[244px] sm:max-w-[358px] inline-block;
}

.chat-bubble-content-ai {
  @apply bg-gray-200 text-gray-900 w-full;
}

/* Form styles */
.form-container {
  @apply flex items-center space-x-2 bg-gray-100 rounded-xl p-1.5;
}

.send-button {
  @apply bg-[#5E3AEE] hover:bg-[#4E2ADE] text-white rounded-full p-2 min-w-[40px] h-[40px] flex items-center justify-center;
}

/* Dropdown styles */
.dropdown-item {
  @apply py-2 px-4 hover:bg-gray-100 cursor-pointer;
}

.dropdown-title {
  @apply font-medium text-gray-900;
} 