@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import './styles/components.css';

@layer utilities {
  .full-viewport-height {
    height: 100vh;
  }

  @supports (height: 100dvh) {
    .full-viewport-height {
      height: 100dvh;
    }
  }
}

/* Any additional global styles can go here */
html, body, #root {
  height: 100vh;  /* standard definition (as fallback) */
  height: 100dvh; /* dynamic vh will make the trick on iOS */
}

body {
  @apply font-sans antialiased overflow-y-auto;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Reset default margins and paddings */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Ensure the root element takes full viewport height */
html, body, #root {
  height: 100vh;  /* standard definition (as fallback) */
  height: 100dvh; /* dynamic vh will make the trick on iOS */
}

body {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Base layout styles */
.app-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}

/* Header styles */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 48px;
}

/* Main content area */
.main-content {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  margin-top: 48px;
  margin-bottom: 64px;
}

/* Footer styles */
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

/* Utility classes */
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.full-width {
  width: 100%;
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .main-content {
    padding: 0.5rem;
  }
}

@supports (-webkit-touch-callout: none) {
  .min-h-screen {
    min-height: -webkit-fill-available;
  }
  .max-h-screen {
    max-height: -webkit-fill-available;
  }
}

/* Add new typography classes */
h1, h2, h3, h4, h5, h6,
.font-heading {
  font-family: "orpheus-pro", Georgia, serif;
}

p, span, div, button, input, textarea, select,
.font-body {
  font-family: "Poppins", sans-serif;
}

/* Add these styles to your CSS */
.chat-container {
  max-width: 768px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.message-container {
  max-width: 65%;
}

/* Add or update these styles */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

body {
  overflow-y: auto;
}

.page-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Ensure the gradient background extends fully */
.min-h-screen {
  min-height: 100vh;
  height: auto;
}

/* Add these global styles to ensure proper overflow behavior */
.overflow-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.message-container {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

/* Add these z-index rules */
.modal-overlay {
  z-index: 50;
}

.chat-container {
  z-index: 10;
}

.popup-container {
  z-index: 100;
}

/* Update mobile-specific styles */
@media (max-width: 768px) {
  .chat-container {
    padding: 0;
  }
  
  /* Remove fixed height constraints for context panel */
  .context-panel {
    height: auto;
    border-bottom: 1px solid #e5e7eb;
    margin-bottom: 1rem;
    overflow: visible;
  }
  
  /* Keep chat panel scrollable with increased fixed height */
  .chat-panel {
    height: 640px;
    overflow: hidden;
  }
  
  /* Adjust container padding */
  .container {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
